"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContentStatusEnglish = exports.ContentStatus = exports.ContentType = void 0;
var ContentType;
(function (ContentType) {
    ContentType["VIDEO"] = "v";
    ContentType["ARTICLE"] = "a";
    ContentType["EPISODE"] = "e";
    ContentType["PODCAST"] = "p";
})(ContentType || (exports.ContentType = ContentType = {}));
var ContentStatus;
(function (ContentStatus) {
    ContentStatus["IN_REVIEW"] = "r";
    ContentStatus["DRAFT"] = "d";
    ContentStatus["PUBLISHED"] = "p";
})(ContentStatus || (exports.ContentStatus = ContentStatus = {}));
exports.ContentStatusEnglish = {
    [ContentStatus.DRAFT]: 'Draft',
    [ContentStatus.IN_REVIEW]: 'In Review',
    [ContentStatus.PUBLISHED]: 'Published',
};
